import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import classNames from "classnames";
import PieceTable from "./PieceTable";
import LoadingIcon from "../LodingIcon";
import AnimatedHeight from "../AnimatedHeight";
import { useNavigate } from "react-router-dom";
import ShipmentItemTable from "./ShipmentItemTable";
import ShipmentTableCheck from "./ShipmentTableCheck";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  PrintPieceLabel,
  PrintShipmentLabel,
  ShipmentDeclaredDocumentRequestDto,
  alertService,
  getAccount,
  getAllDeclaredShipmentDocuments,
  getAvailableCoupons,
  getDocument,
  getService,
  getShipment,
  getShipmentRates,
  getShipmentTracking,
  loaderService,
  redeemCoupon,
  updateShipment,
  updateShipmentStatus,
  verifyAccountBlackList,
} from "../../services";
import {
  ShipmentStatus,
  DocumentStatus,
  ShipmentInterface,
  PaymentMode,
  ShipmentTracking,
  ShipmentService,
  AccountInterface,
  BusinessUnitInterface,
  LocationInterface,
  DeliveryType,
  DocumentInterface,
  PaymentStatusEnum,
} from "../../interfaces";
import {
  formatDate,
  paymentModeFormat,
  documentStatusFormat,
  shipmentStatusFormat,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {
  PickupPhase,
  setPickupCoupon,
  setPickupDocument,
  setPickupOwner,
  setPickupPayments,
  setPickupPhase,
  setPickupShipments,
  setPickupTaxes,
} from "../../store/slices";
import { PickupModal } from "./PickupModal";
import DropdownMenu from "../DropdownMenu";
import moment from "moment-timezone";
import { CouponDTO } from "../../interfaces/Dtos/CouponDTO";
import { StoreShipmentInterface } from "../../interfaces/Dtos";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import OptionalShipmentItems from "./OptionalShipmentItems";
import { ServiceDTO } from "../../interfaces/Dtos/ServiceDTO";

enum PrintOption {
  SHIPMENT,
  DOCUMENT,
}
const options = [
  { title: "Etiqueta", value: PrintOption.SHIPMENT },
  { title: "Documento Guía", value: PrintOption.DOCUMENT },
];

export interface ShipmentDetailsModalProps {
  shipmentNumber: string;
  openModal: boolean;
  shipment?: ShipmentInterface;
  shipments?: StoreShipmentInterface[];
  hideButtons?: boolean;
  onReload?: () => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ShipmentDetailsModal: FC<ShipmentDetailsModalProps> = ({
  shipment,
  shipments,
  shipmentNumber,
  openModal,
  hideButtons,
  onReload = () => {},
  setOpenModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const [refresh, setRefresh] = useState(0);
  const loading = loaderService.useIsLoading();
  const [details, setDetails] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [shipment_, setShipment] = useState(shipment);
  const [pickupMode, setPickupMode] = useState(false);
  const [service, setService] = useState<ServiceDTO>();
  const [openPopover, setOpenPopover] = useState(false);
  const [aproximated, setAproximated] = useState(false);
  const [openAnulation, setOpenAnulation] = useState(false);
  const [deliveryDistance, setDeliveryDistance] = useState(0);
  const [document_, setDocument] = useState<DocumentInterface>();
  const [openPickupModal, setOpenPickupModal] = useState(false);
  const [tracking, setTracking] = useState<ShipmentTracking[]>([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmModify, setOpenConfirmModify] = useState(false);
  const [openOptionalItems, setOpenOptionalItems] = useState(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>(
    undefined
  );
  const [selectedShipmentNumbers, setSelectedShipmentNumbers] = useState<
    string[]
  >([]);

  const isAnulable = useMemo(() => {
    return (
      !!shipment_ &&
      (shipment_.status === ShipmentStatus.ACTIVE ||
        shipment_.status === ShipmentStatus.DRAFT) &&
      !shipment_.document &&
      shipment_.buSource?.code === user?.businessUnit?.code &&
      shipment_.lastBU === user?.businessUnit?.code &&
      moment(shipment_.createdAt).isSame(shipment_.lastBUDate, "day") &&
      !shipment_.deliveryCreationUser
    );
  }, [shipment_, user]);

  const isModifiable = useMemo(() => {
    return (
      !!shipment_ &&
      (shipment_.status === ShipmentStatus.DRAFT ||
        shipment_.status === ShipmentStatus.ACTIVE) &&
      (!shipment_.document || document_?.status === DocumentStatus.PENDING) &&
      shipment_.buSource?.code === user?.businessUnit?.code
    );
  }, [shipment_, user, document_?.status]);

  const ipostel = useMemo(() => {
    return shipment_?.totalPostalTaxBaseCurr === 0
      ? shipment_?.items.reduce((acc, item) => acc + item.rate.ipostel, 0)
      : shipment_?.totalPostalTaxBaseCurr;
  }, [shipment_?.items, shipment_?.totalPostalTaxBaseCurr]);

  const totalWithTaxes = useMemo(() => {
    return (
      (shipment_?.total ?? 0) +
      (shipment_?.items.reduce((acc, item) => acc + item.rate.iva, 0) ?? 0) +
      (ipostel ?? 0)
    );
  }, [shipment_, ipostel]);

  const isPaid = useMemo(() => {
    return (
      !!document_ &&
      (document_.status === DocumentStatus.PAID ||
        document_.balanceAmount -
          document_.payments
            .filter((p) => p.status === PaymentStatusEnum.PENDING)
            .reduce((acc, p) => acc + p.amount, 0) <=
          0.01)
    );
  }, [document_]);

  const isPrintable = useMemo(() => {
    return (
      !!shipment_ &&
      (!!shipment_.substituteFor ||
        shipment_.buSource?.code === user?.businessUnit?.code) &&
      (shipment_.status === ShipmentStatus.ACTIVE ||
        (shipment_.status === ShipmentStatus.DRAFT &&
          (shipment_.paymentMode === PaymentMode.CREDIT ||
            shipment_.paymentMode === PaymentMode.COD))) &&
      (shipment_.paymentMode !== PaymentMode.CONTADO || isPaid)
    );
  }, [shipment_, user, isPaid]);

  const isDeliverable = useMemo(() => {
    return (
      !!shipment_ &&
      shipment_.status === ShipmentStatus.ACTIVE &&
      shipment_.buConsignee?.code === user?.businessUnit?.code &&
      shipment_.lastBU === user?.businessUnit?.code &&
      (shipment_.paymentMode !== PaymentMode.CONTADO || isPaid) &&
      ((shipment_?.items.find((item) => item.isItemBase)?.rate.value ?? 0) >
        0 ||
        isPaid ||
        shipment_?.paymentMode === PaymentMode.CREDIT ||
        shipment_?.paymentMode === PaymentMode.BOXOFFICE_CREDIT)
    );
  }, [shipment_, user, isPaid]);

  const itemsAreModifiable = useMemo(() => {
    return (
      !!shipment_ &&
      shipment_.buConsignee?.code === user?.businessUnit?.code &&
      shipment_.lastBU === user?.businessUnit?.code &&
      shipment_.paymentMode === PaymentMode.COD &&
      !shipment_.document
    );
  }, [shipment_, user]);

  const handleDocumentView = async () => {
    if (
      !document_?.documentID ||
      (!user.user?.businessUnitList.some(
        (bu) => bu.code === document_?.buCodeInvoicer
      ) &&
        user?.user?.roleName !== "Superadministrador")
    )
      return;

    const document = await getDocument(document_.documentID);
    if (!document) return;

    navigate(`/documents/${document.documentID}`);
  };

  const handlePickup = async () => {
    if (!shipment_?.id) return;

    let coupon: CouponDTO | undefined = undefined;

    // Get real shipments
    loaderService.start();
    const shipments = await Promise.all(
      selectedShipmentNumbers.map((shipmentNumber) =>
        getShipment(shipmentNumber)
      )
    ).then((shipments) => shipments.filter((s) => !!s) as ShipmentInterface[]);

    if (!shipments.length) {
      alertService.error(
        "Hubo un error al obtener los datos completos de cada guía."
      );
      loaderService.stop();
      return;
    }

    // Get document data
    const document = !!shipments[0].document
      ? (await getDocument(
          shipments[0].document?.documentId,
          shipments[0].document?.documentNumber
        )) ?? undefined
      : undefined;

    // Get owner data
    const ownerID = !!document
      ? document.accountOwner?.id
      : shipments[0].paymentMode === PaymentMode.CONTADO ||
        shipments[0].paymentMode === PaymentMode.BOXOFFICE_CREDIT
      ? shipments[0].shipper.id
      : shipments[0].paymentMode === PaymentMode.COD ||
        shipments[0].paymentMode === PaymentMode.DEST_BOXOFFICE_CREDIT
      ? shipments[0].consignee.id
      : undefined;
    const owner = ownerID ? await getAccount(ownerID) : undefined;

    if (!document) {
      // Verify coupons
      const shipment = shipments[0];

      const date = moment();

      // Verify available coupons
      const coupons = await getAvailableCoupons(
        shipments.length,
        businessUnits.find((bu) => bu.code === shipment.buConsignee?.code)!
          .location.locationID!,
        shipment.paymentMode!,
        shipment.deliveryType,
        shipments.reduce((acc, shipment) => acc + +shipment.declaredValue!, 0),
        shipments.reduce(
          (acc, shipment) => acc + +shipment.totalChargedWeight!,
          0
        ),
        date.tz("America/Caracas").format("YYYY-MM-DDTHH:mm:ss")
      );

      if (coupons.didError) {
        alertService.error(
          "Error al obtener los cupones",
          coupons.errorMessage
        );
      }

      if (
        coupons.model?.length &&
        !shipment.items?.some((item) => item.id === "3010")
      ) {
        coupon = coupons.model[0];

        const isBlocked = await verifyAccountBlackList(
          ownerID!,
          coupon.couponID
        );

        if (
          !isBlocked.didError &&
          isBlocked.model !== null &&
          !isBlocked.model
        ) {
          // Redeem coupon
          const redeemedCoupon = await redeemCoupon(
            coupon.couponID,
            shipment.id ?? "",
            shipment.buSource.code,
            shipment.buConsignee?.code ?? "",
            shipments.reduce(
              (acc, shipment) => acc + +shipment.totalChargedWeight!,
              0
            ),
            new Date(),
            user?.user?.userLogin ?? ""
          );

          if (redeemedCoupon.didError) {
            coupon = undefined;
            alertService.error(
              "Error al redimir el cupón",
              redeemedCoupon.errorMessage
            );
          } else {
            const couponItems = redeemedCoupon.model!.items.map((item) => ({
              id: item.itemID.toString(),
              code: item.itemCode,
              name: item.itemName,
              order: 0,
              mandatory: false,
              isItemBase: item.isItemBase,
              rate: {
                value: -redeemedCoupon.model!.couponValue,
                isPercentage: redeemedCoupon.model!.isPercentage,
                iva: 0,
                ipostel: 0,
                distance: 0,
                tierCode: "",
                tierName: "",
              },
            }));

            // Add coupon items to shipments
            for (const shipment of shipments) {
              shipment.items = [...shipment.items, ...couponItems];
            }
          }
        }
      }

      // Get items rate
      for (const shipment of shipments) {
        const response = await getShipmentRates(
          shipment.service ?? ShipmentService.STANDARD,
          shipment.paymentMode ?? PaymentMode.COD,
          shipment.deliveryType ?? DeliveryType.AT_OFFICE,
          shipment.buSource?.code ?? "",
          shipment.buConsignee?.code ?? "",
          shipment.shipper.id,
          shipment.consignee.id,
          shipment.accountBillTo?.id,
          businessUnits.find((bu) => bu.code === shipment.buSource?.code!)!
            .location,
          shipment.consigneeAddress,
          shipment.items,
          shipment.pieces,
          shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
          new Date().toISOString(),
          applicationID,
        );

        if (response.didError || !response.model) {
          alertService.error(
            `Hubo un error al obtener la tarifa de la guía ${shipment.shipmentNumber}.`
          );
          setOpenModal(false);
          loaderService.stop();
          return;
        }

        shipment.items = response.model.items
          .filter(
            (item) =>
              (item.mandatory ||
                shipment.items?.some((s) => s.id === item.id)) &&
              item.id !== "30"
          )
          .sort((a, b) => a.order - b.order);

        if (coupon) {
          const shipmentUpdated = await updateShipment(
            {
              id: shipment.id,
              items: shipment.items,
              service: shipment.service,
              paymentMode: shipment.paymentMode,
              deliveryType: shipment.deliveryType,
              status: shipment.status,
              pieces: shipment.pieces,
            },
            true,
            false,
            user.user ?? undefined,
            applicationID
          );
          if (shipmentUpdated.didError || !shipmentUpdated.model) {
            alertService.error(
              `Hubo un error al actualizar la guía ${shipment.shipmentNumber}.`
            );
            loaderService.stop();
            return;
          }
        }

        const absoluteTotal = shipment.items
          .filter((item) => !item.rate.isPercentage)
          .reduce((total, item) => total + item.rate.value, 0);
        const percentageTotal = shipment.items
          .filter((item) => item.rate.isPercentage)
          .reduce((total, item) => total + item.rate.value, 0);
        const subtotal = absoluteTotal * (1 + percentageTotal / 100);

        shipment.total = subtotal;
      }
    }

    loaderService.stop();

    dispatch(setPickupOwner(owner ?? undefined));
    dispatch(setPickupPayments([]));
    dispatch(setPickupDocument(document));
    dispatch(setPickupTaxes(document?.taxes ?? []));
    dispatch(setPickupShipments(shipments));
    dispatch(setPickupPhase(PickupPhase.PAYMENT));
    dispatch(setPickupCoupon(coupon));
    navigate("/pick-up");
  };

  const handleModify = async () => {
    navigate("/shipment-edit", {
      state: {
        id: shipment_?.id,
        shipmentNumber: shipment_?.shipmentNumber,
        initialValues: {
          shipper: shipment_?.shipper,
          boxAccount: shipment_?.boxAccount,

          consignee: shipment_?.consignee,
          buConsignee: shipment_?.buConsignee,
          consigneeAddress: shipment_?.consigneeAddress,

          accountBillTo: shipment_?.accountBillTo,

          discount: "",
          items: shipment_?.items,
          pieces: shipment_?.pieces,
          service: shipment_?.service,
          paymentMode: shipment_?.paymentMode,
          deliveryType: shipment_?.deliveryType,
          observations: shipment_?.observations,
          total: shipment_?.total,
        },
      },
    });
  };

  const handlePrintShipment = async () => {
    if (!shipment_ || !shipment_.shipmentNumber || !shipment_.service) {
      alertService.error(
        "Error al imprimir la etiqueta",
        "No se encontraron los identificadores de la guia.",
        { autoClose: false }
      );
      return;
    }
    loaderService.start();
    alertService.info(
      "Guía: " + shipment_.shipmentNumber,
      "Imprimiendo etiqueta..."
    );
    const printSucceed = await PrintShipmentLabel(
      +shipment_.shipmentNumber,
      shipment_.service ?? 0,
      shipment_.buSource.code
    );
    loaderService.stop();
    if (!!printSucceed) {
      alertService.success(
        "Guía:" + shipment_.shipmentNumber,
        "La etiqueta se imprimió correctamente."
      );
    }
  };

  const handlePrintShipmentPiece = async (pieceNumber: number) => {
    if (!shipment_ || !shipment_.shipmentNumber || !shipment_.service) {
      alertService.error(
        "Error al imprimir la etiqueta",
        "No se encontraron los identificadores de la guia.",
        { autoClose: false }
      );
      return;
    }
    loaderService.start();
    alertService.info(
      "Guía: " + shipment_.shipmentNumber + " - Pieza: " + pieceNumber,
      "Imprimiendo etiqueta..."
    );
    const printSucceed = await PrintPieceLabel(
      +shipment_.shipmentNumber,
      shipment_.service ?? 0,
      shipment_.buSource.code,
      [pieceNumber]
    );
    loaderService.stop();
    if (!!printSucceed) {
      alertService.success(
        "Guía:" + shipment_.shipmentNumber,
        "La etiqueta se imprimió correctamente."
      );
    }
  };

  const handleDownloadDocuments = async () => {
    if (!shipment_ || !shipment_.shipmentNumber || !shipment_.id) {
      alertService.error(
        "Error al imprimir los documentos",
        "No se encontraron los identificadores de la guia.",
        { autoClose: false }
      );
      return;
    }

    const shipmentDocumentsRequest: ShipmentDeclaredDocumentRequestDto = {
      shipmentNumber: shipment_.shipmentNumber ?? "",
      shipmentHeaderID: shipment_.id,
    };

    loaderService.start();
    alertService.info("Imprimiendo documentos...");
    const fileResult = await getAllDeclaredShipmentDocuments(
      [shipmentDocumentsRequest],
      shipment_.document?.documentId
    );
    loaderService.stop();
    if (!!fileResult) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(fileResult.file);

      // Create a link element
      const a = document?.createElement("a");
      a.href = fileURL;
      a.download = fileResult.fileName; // Set the filename for the downloaded file
      a.click();

      /// In case need to force print
      /*const printWindow = window.open(fileURL, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      } else {
        console.error("Could not open print window");
      }*/

      // Remember to handle Blob URL cleanup when done
      URL.revokeObjectURL(fileURL);
      alertService.success("Documento impreso satisfactoriamente.");
    } else {
      alertService.error("No se pudo descargar el documento.");
    }
  };

  const handleDropdownMenuSelect = (option: { value: PrintOption }) => {
    setOpenModal(false);
    if (option.value === PrintOption.SHIPMENT) {
      handlePrintShipment();
    } else {
      handleDownloadDocuments();
    }
  };

  const redirectToGoogleMaps = () => {
    const lat = shipment_?.consigneeAddress?.coordinates.lat ?? 0;
    const lng = shipment_?.consigneeAddress?.coordinates.lng ?? 0;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleAnulation = async () => {
    if (!shipment_?.id) return;

    const response = await updateShipmentStatus(
      ShipmentStatus.ANNULLED,
      shipment_?.id,
      shipment_?.shipmentNumber ?? "",
      user.user ?? undefined
    );

    if (response.didError) {
      alertService.error(
        "Error al anular la guía",
        response.errorMessage ?? "No se pudo anular la guía."
      );
    } else {
      alertService.success("Guía anulada", "La guía se anuló correctamente.");
      updateModalShipment();
      setOpenAnulation(false);
    }
  };

  const updateModalShipment = async () => {
    if (!shipmentNumber || !!shipment || !openModal) return;

    getShipment(shipmentNumber).then((shipment) => {
      if (!shipment) {
        alertService.error(`No se pudo cargar la guía ${shipmentNumber}.`);
        setOpenModal(false);
        return;
      }

      if (!shipment.document) {
        getShipmentRates(
          shipment.service ?? ShipmentService.STANDARD,
          shipment.paymentMode ?? PaymentMode.COD,
          shipment.deliveryType,
          businessUnits.find((bu) => bu.code === shipment.buSource?.code!)!
            .code ?? "",
          shipment.buConsignee?.code ?? "",
          shipment.shipper.id,
          shipment.consignee.id,
          shipment.accountBillTo?.id,
          businessUnits.find((bu) => bu.code === shipment.buSource?.code!)
            ?.location,
          shipment.consigneeAddress,
          shipment.items,
          shipment.pieces,
          shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
          new Date().toISOString(),
          applicationID,
        ).then((response) => {
          const rates = response.model?.items;
          if (!!rates) {
            setAproximated(true);
            setDeliveryDistance(
              response.model?.distance ?? shipment.deliveryDistance ?? 0
            );

            setShipment({
              ...shipment,
              items: rates,
              total: rates.reduce((acc, i) => acc + i.rate.value, 0),
            });
          } else {
            alertService.warn(
              `Hubo un error al obtener la tarifa de la guía ${shipment.shipmentNumber}.`
            );
            setShipment(shipment);
          }
        });
      } else {
        setDeliveryDistance(shipment.deliveryDistance ?? 0);
        setShipment(shipment);
      }

      setSelectedShipmentNumbers([shipment.shipmentNumber!]);
    });
  };

  useEffect(() => {
    updateModalShipment();
  }, [
    shipmentNumber,
    shipment,
    openModal,
    businessUnits,
    refresh,
    setOpenModal,
  ]);

  useEffect(() => {
    const getShipmentService = async () => {
      if (!shipment_?.service || !openModal) return;

      const service = await getService(shipment_.service);

      if (service.didError || !service.model) {
        return;
      }

      setService(service.model);
    };

    getShipmentService();
  }, [shipment_, openModal]);

  useEffect(() => {
    if (!shipment && openModal) {
      setDelivered(false);
      setShipment(undefined);
      setSelectedShipmentNumbers([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    setShipment(shipment);
    setDeliveryDistance(shipment?.deliveryDistance ?? 0);
  }, [shipment]);

  useEffect(() => {
    const documentID = shipment_?.document?.documentId;
    if (!documentID) return;

    const fetchDocument = async () => {
      const document = await getDocument(documentID);
      if (!document) return;

      setDocument(document);
    };

    fetchDocument();
  }, [shipment_]);

  useEffect(() => {
    const service = shipment_?.service;
    const buSource = shipment_?.buSource?.code;
    const shipmentNumber = shipment_?.shipmentNumber;

    if (!shipmentNumber || !service || !buSource) return;

    const getTracking = async () => {
      const tracking = await getShipmentTracking(
        shipmentNumber,
        service,
        buSource
      );
      if (!tracking) return;

      setTracking(
        tracking.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        })
      );
    };

    getTracking();
  }, [shipment_]);

  useEffect(() => {
    setCenter({
      lat: shipment_?.consigneeAddress?.coordinates.lat ?? 0,
      lng: shipment_?.consigneeAddress?.coordinates.lng ?? 0,
    });
  }, [shipment_]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="bg-gray-200"
    >
      <div
        className={classNames(
          "p-4 flex flex-1 flex-col gap-2 min-w-[60rem] mb-8",
          (!shipment_ || delivered) && "hidden"
        )}
      >
        {/* Header */}
        <div className="flex flex-row items-center justify-between bg-white rounded-lg">
          <div>
            <div className="flex flex-col md:flex-row md:gap-2">
              <p className="font-bold text-xl">Guía:</p>
              <p className="text-indigo-600 font-bold text-xl">
                {shipmentNumber}
              </p>

              <p
                className={classNames(
                  "font-bold text-xl text-gray-800",
                  !shipment_?.status && "hidden"
                )}
              >
                (
                {shipmentStatusFormat(
                  shipment_?.status ?? ShipmentStatus.ACTIVE
                )}
                )
              </p>
            </div>

            <div className="flex flex-col md:flex-row md:gap-2 text-sm">
              <p className="text-gray-600">Creación:</p>
              <p className="text-gray-800 font-semibold">
                {!!shipment_?.createdAt &&
                  new Date(shipment_.createdAt)
                    .toLocaleDateString("es-VE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                    .replace("a", "AM")
                    .replace("p", "PM")
                    .slice(0, -4)}
              </p>
            </div>
          </div>

          <button
            type="button"
            className="flex items-center justify-center relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => setOpenModal(false)}
          >
            <span className="absolute -inset-2.5" />
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div
          className={classNames(
            "flex flex-row items-center text-lg gap-2 -mt-2 font-semibold",
            !shipment_?.substituteFor && "hidden"
          )}
        >
          <p>Sustituto de:</p>
          <LinkText
            text={shipment_?.substituteFor ?? ""}
            onClick={() => {
              setOpenModal(false);
              navigate(`/shipments/${shipment_?.substituteFor}`);
            }}
          />
        </div>

        <div
          className={classNames(
            "flex flex-row items-center text-lg gap-2 -mt-2 font-semibold",
            !shipment_?.substitutedBy && "hidden"
          )}
        >
          <p>Sustituido por:</p>
          <LinkText
            text={shipment_?.substitutedBy ?? ""}
            onClick={() => {
              setOpenModal(false);
              navigate(`/shipments/${shipment_?.substitutedBy}`);
            }}
          />
        </div>

        <hr className="my-2" />

        {/* Main data */}
        <div className="flex flex-1 gap-4">
          {/* Shipper */}
          <ShipmentDetailsClientData
            isShipper
            client={shipment_?.shipper}
            businessUnit={shipment_?.buSource}
          />

          {/* Consignee */}
          <ShipmentDetailsClientData
            client={shipment_?.consignee}
            businessUnit={shipment_?.buConsignee}
            location={shipment_?.consigneeAddress}
            showLocation={shipment_?.deliveryType === 20}
          />

          {/* Resume */}
          <div className="flex flex-col items-end text-gray-800 leading-tight">
            <p className="font-medium text-lg leading-none">
              {paymentModeFormat(shipment_?.paymentMode).toUpperCase()}
            </p>
            <p className="mb-2">
              Servicio:{" "}
              <span className="font-semibold">{service?.serviceShortName}</span>
            </p>
            <p
              className={classNames(
                "leading-none mb-2",
                shipment_?.paymentMode !== PaymentMode.CREDIT && "hidden"
              )}
            >
              {shipment_?.accountBillTo?.accountFullName}
            </p>
            <p className="font-semibold">
              <span className="font-normal">Con impuestos: </span>
              {currencyExchangeText(totalWithTaxes, exchanges, "USD")}
            </p>
            <p className="">
              Nº de piezas:{" "}
              <span className="font-semibold">{shipment_?.pieces.length}</span>
            </p>
            <p className="">
              {!!document_?.urlDocument
                ? "Peso facturado: "
                : "Peso por facturar: "}
              <span className="font-semibold">
                {shipment_?.totalChargedWeight?.toFixed(2)} Kg
              </span>
            </p>
            <p className="text-xs mt-2">
              Peso balanza:{" "}
              <span className="font-semibold">
                {shipment_?.totalPhysicalWeight?.toFixed(2)} Kg
              </span>
            </p>
            <p className="text-xs">
              Peso volumétrico:{" "}
              <span className="font-semibold">
                {shipment_?.totalDimensionalWeight?.toFixed(2)} Kg
              </span>
            </p>
          </div>
        </div>
        <hr className="my-2" />

        {/* Details */}
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex items-center w-full gap-4">
            <p className="font-light text-xl">Detalles</p>

            <div
              className={classNames(
                "p-2 hover:bg-gray-200 border cursor-pointer rounded-full",
                pickupMode && "bg-gray-100 opacity-500 pointer-events-none"
              )}
              onClick={() => setDetails(!details)}
            >
              <ChevronDownIcon
                style={{
                  transform: `rotate(${!details ? 0 : 180}deg)`,
                  transitionDuration: `${details}ms`,
                }}
                className="h-6 w-6 flex-none text-gray-400 transition-all ease-in-out duration-400"
                aria-hidden="true"
              />
            </div>
          </div>

          <AnimatedHeight
            duration={1000}
            active={details}
            className="flex flex-col px-8 gap-6"
          >
            <div
              className={classNames("flex flex-col", !document_ && "hidden")}
            >
              <p className="font-medium text-gray-800 text-xl"> Factura </p>
              <table className="table-auto w-full mt-4">
                <thead>
                  <tr>
                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      ID FACTURA
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      FECHA DE FACTURA
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      A NOMBRE DE
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      TOTAL FACTURA
                    </th>
                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      MONTO POR COBRAR
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                      MONTO A REINTEGRAR
                    </th>
                    {/* HIDE until developed}
                        <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                          CANT. DE GUIAS
                        </th>
                        */}

                    <th className="text-right px-4 py-2 font-semibold text-xs">
                      ESTADO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={classNames(
                      "bg-gray-50",
                      (user.user?.businessUnitList.some(
                        (bu) => bu.code === document_?.buCodeInvoicer
                      ) ||
                        user?.user?.roleName === "Superadministrador") &&
                        "cursor-pointer hover:bg-gray-100 "
                    )}
                    onClick={handleDocumentView}
                  >
                    <td
                      className="px-4 py-4 text-xs text-gray-500 font-semibold truncate"
                      style={{ maxWidth: "10rem" }}
                    >
                      {document_?.documentNumber}
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      {!!document_?.documentDate &&
                        formatDate(document_.documentDate?.toISOString())}
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {document_?.accountOwner?.accountFullName}
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      <span className="font-medium">
                        {currencyExchangeText(
                          document_?.total ?? 0,
                          exchanges,
                          "USD"
                        )}
                      </span>
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      <span className="font-medium">
                        {currencyExchangeText(
                          Math.max(0, document_?.balanceAmount ?? 0),
                          exchanges,
                          "USD"
                        )}
                      </span>
                    </td>

                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                      <span className="font-medium">
                        {currencyExchangeText(
                          (document_?.balanceAmount ?? 0) < 0
                            ? -1 * document_?.balanceAmount!
                            : 0,
                          exchanges,
                          "USD"
                        )}
                      </span>
                    </td>
                    {/* HIDE until developed}
                        <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                          <span className="font-light">
                            En desarrollo
                          </span>
                        </td>
                          */}

                    <td className="px-4 py-4 text-xs text-right text-gray-500 font-semibold truncate">
                      {documentStatusFormat(
                        document_?.status ?? DocumentStatus.PENDING
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr className={classNames("my-4", !document_ && "hidden")} />

            <div
              className={classNames("flex flex-col", !shipment_ && "hidden")}
            >
              <p className="font-medium text-gray-800 text-xl"> Piezas </p>
              <PieceTable
                pieces={shipment_?.pieces ?? []}
                printable={isPrintable}
                onPrint={handlePrintShipmentPiece}
              />
            </div>

            <hr className="my-8" />

            <div
              className={classNames("flex flex-col", !shipment_ && "hidden")}
            >
              <p className="font-medium text-gray-800 text-xl mb-4"> Items </p>
              <ShipmentItemTable
                showTaxes
                total={shipment_?.total ?? 0}
                items={shipment_?.items ?? []}
                distance={deliveryDistance}
                ipostel={ipostel ?? 0}
                declaredValue={+(shipment_?.declaredValue ?? "0")}
              />
              {aproximated && (
                <p className="text-xs text-right text-gray-400">
                  *Los montos son estimados.
                </p>
              )}
            </div>

            <hr
              className={classNames(
                "my-4",
                !shipment_?.observations && "hidden"
              )}
            />

            <div
              className={classNames(
                "flex flex-col",
                !shipment_?.observations && "hidden"
              )}
            >
              <p className="font-medium text-gray-800 text-xl mb-4">
                {" "}
                Observaciones{" "}
              </p>

              <p className="text-gray-800">{shipment_?.observations}</p>
            </div>

            <hr
              className={classNames(
                "my-4",
                !shipment_?.deliveryCreationUser && "hidden"
              )}
            />

            <div
              className={classNames(
                "flex flex-col",
                !shipment_?.deliveryCreationUser && "hidden"
              )}
            >
              <p className="font-medium text-gray-800 text-xl mb-4">
                {" "}
                Entrega{" "}
              </p>

              <p className="text-gray-800">
                <span className="font-semibold">Usuario:</span>{" "}
                {shipment_?.deliveryCreationUser}
              </p>
              <p className="text-gray-800">
                <span className="font-semibold">Fecha:</span>{" "}
                {moment(shipment_?.deliveryDate).format("YYYY-MM-DD")}
              </p>
              <p className="text-gray-800">
                <span className="font-semibold">Observaciones:</span>{" "}
                {shipment_?.deliveryObservation}
              </p>
            </div>

            <hr
              className={classNames("my-4", tracking.length === 0 && "hidden")}
            />

            <div
              className={classNames(
                "flex flex-col",
                tracking.length === 0 && "hidden"
              )}
            >
              <p className="font-medium text-gray-800 text-xl mb-4">
                {" "}
                Tracking{" "}
              </p>

              <table className="table-auto w-full mb-8">
                <thead>
                  <tr>
                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      ESTADO
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      FECHA
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      UBICACIÓN
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      TIENDA
                    </th>

                    <th className="text-left px-4 py-2 font-semibold text-xs">
                      DESCRIPCIÓN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tracking.map((t, i) => (
                    <tr key={i} className="hover:bg-gray-100 bg-gray-50">
                      <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                        {t.status}
                      </td>

                      <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                        {t.date}
                      </td>

                      <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                        {t.city} - {t.state}
                      </td>

                      <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                        {t.locationZone}
                      </td>

                      <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
                        {t.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <hr
              className={classNames(
                "my-10",
                shipment_?.deliveryType !== 20 && "hidden"
              )}
            />

            <div
              className={classNames(
                "h-[20rem]",
                shipment_?.deliveryType !== 20 && "hidden"
              )}
            >
              <p className="font-medium text-gray-800 text-xl mb-4">
                {" "}
                Dirección de entrega en Google Maps{" "}
              </p>

              <GoogleMap
                id="map"
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                zoom={17}
                center={center}
              >
                <MarkerF
                  onClick={redirectToGoogleMaps}
                  position={{
                    lat: shipment_?.consigneeAddress?.coordinates.lat ?? 0,
                    lng: shipment_?.consigneeAddress?.coordinates.lng ?? 0,
                  }}
                />
              </GoogleMap>
            </div>
          </AnimatedHeight>
        </div>

        <hr className="my-2" />

        {/* Pickup Mode */}
        <AnimatedHeight
          duration={1000}
          active={pickupMode}
          className="flex flex-col"
        >
          <p className="font-light text-xl mb-4">Paquetes a entregar</p>

          <div className="px-8">
            <ShipmentTableCheck
              fixed={shipment_?.shipmentNumber}
              shipments={shipments ?? []}
              selectedShipmentNumbers={selectedShipmentNumbers}
              setSelectedShipmentNumbers={setSelectedShipmentNumbers}
            />
          </div>

          <hr className="mt-6 mb-2" />
        </AnimatedHeight>

        {/* Buttons */}
        <div
          className={classNames(
            "flex flex-1 justify-between mt-8",
            hideButtons && "hidden"
          )}
        >
          {isAnulable && (
            <div hidden={user.user?.roleName === "Consulta Innovus"}>
              <SecondaryButton
                onClick={() => setOpenAnulation(true)}
                className="w-40"
              >
                Anular
              </SecondaryButton>
            </div>
          )}

          {isModifiable && (
            <PrimaryButton onClick={handleModify} className="hidden w-40">
              Modificar
            </PrimaryButton>
          )}

          {isPrintable && (
            <div className="relative">
              <PrimaryButton
                onClick={(e) => setOpenPopover(!openPopover)}
                className="relative w-40"
              >
                Imprimir
                <ChevronDownIcon
                  className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 ml-2"
                  aria-hidden="true"
                />
              </PrimaryButton>

              <DropdownMenu
                open={openPopover}
                setOpen={setOpenPopover}
                onSelect={handleDropdownMenuSelect}
                options={options}
                className="!mt-2 w-full"
              />
            </div>
          )}

          {isDeliverable && (
            <div>
              {pickupMode && (
                <SecondaryButton
                  className="w-40"
                  onClick={() => {
                    setPickupMode(false);
                  }}
                >
                  Cancelar
                </SecondaryButton>
              )}
            </div>
          )}

          {isDeliverable && (
            <div className="flex items-center gap-4">
              <LinkText
                text="Gestionar servicios adicionales"
                className={classNames(
                  "text-sm",
                  !itemsAreModifiable && "hidden"
                )}
                onClick={() => setOpenOptionalItems(true)}
              />
              <div hidden={user.user?.roleName === "Consulta Innovus"}>
                <PrimaryButton
                  disabled={loading}
                  className="w-40"
                  onClick={() => {
                    if (pickupMode && !isPaid) {
                      handlePickup();
                    } else if (
                      (pickupMode && isPaid) ||
                      shipment_?.paymentMode === PaymentMode.CREDIT ||
                      shipment_?.paymentMode === PaymentMode.BOXOFFICE_CREDIT
                    ) {
                      setOpenPickupModal(true);
                    } else {
                      setDetails(false);
                      setPickupMode(true);
                    }
                  }}
                >
                  {!pickupMode
                    ? "Entregar"
                    : isPaid ||
                      shipment_?.paymentMode === PaymentMode.CREDIT ||
                      shipment_?.paymentMode === PaymentMode.BOXOFFICE_CREDIT
                    ? "Finalizar"
                    : "Pagar"}
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames(
          "flex flex-col items-center justify-center flex-1 p-2",
          (!shipment_ || !delivered) && "hidden"
        )}
      >
        <CheckCircleIcon className="text-green-600 w-32 h-32" />
        <p className="mt-2 text-xl text-center font-light text-gray-900">
          {selectedShipmentNumbers.length > 1
            ? "Las guías han sido entregadas."
            : "La guía ha sido entregada."}
        </p>

        <div className="flex w-full justify-center mt-6">
          <PrimaryButton
            className="px-10"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Aceptar
          </PrimaryButton>
        </div>
      </div>

      <div
        className={classNames(
          "flex flex-col items-center justify-center flex-1 p-8",
          shipment_ && "hidden"
        )}
      >
        <LoadingIcon size="5rem" />
        <p className="mt-2 text-xl text-center font-light text-gray-900">
          Cargando guía...
        </p>
      </div>

      <Modal openModal={openConfirmDelete} setOpenModal={setOpenConfirmDelete}>
        <div className="flex flex-col items-center justify-center max-w-[20rem]">
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea eliminar el envio?{" "}
            {shipment_?.status === ShipmentStatus.ACTIVE && (
              <span>Esta acción necesita la aprobación de casa matriz.</span>
            )}
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenConfirmDelete(false)}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton className="px-4" onClick={() => {}}>
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openConfirmModify} setOpenModal={setOpenConfirmModify}>
        <div className="flex flex-col items-center justify-center max-w-[20rem]">
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Está seguro que desea modificar el envio?{" "}
            {shipment_?.status === ShipmentStatus.ACTIVE && (
              <span>Esta acción necesita la aprobación de casa matriz.</span>
            )}
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenConfirmModify(false)}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton className="px-4" onClick={handleModify}>
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal openModal={openAnulation} setOpenModal={setOpenAnulation}>
        <div className="flex flex-col items-center justify-center max-w-[20rem]">
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32 text-indigo-600" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            ¿Estás seguro que quieres anular la guía{" "}
            <span className="font-bold">{shipment_?.shipmentNumber}</span>?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenAnulation(false)}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton className="px-4" onClick={handleAnulation}>
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <PickupModal
        openModal={openPickupModal}
        setOpenModal={setOpenPickupModal}
        consigneeName={shipment_?.consignee?.accountFullName ?? ""}
        consigneeId={shipment_?.consignee?.identificationNumber ?? ""}
        onSubmit={() => {
          onReload();
          setDelivered(true);
        }}
        shipmentNumbers={selectedShipmentNumbers}
      />

      {shipment_ && (
        <OptionalShipmentItems
          shipment={shipment_}
          open={openOptionalItems}
          selectedShipmentNumbers={selectedShipmentNumbers}
          setOpen={setOpenOptionalItems}
          onRefresh={() => setRefresh(Math.random())}
        />
      )}
    </Modal>
  );
};

interface ShipmentDetailsClientDataProps {
  client?: AccountInterface;
  businessUnit?: BusinessUnitInterface;
  location?: LocationInterface;
  showLocation?: boolean;
  isShipper?: boolean;
}
const ShipmentDetailsClientData: FC<ShipmentDetailsClientDataProps> = ({
  client,
  businessUnit,
  location,
  showLocation,
  isShipper = false,
}) => {
  const countries = useAppSelector((state) => state.inmutable.countries);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const identificationType = useMemo(() => {
    return taxIdentificationTypes.find(
      (t) => t.taxIdentificationTypeId === client?.taxIdentificationTypeID
    );
  }, [client, taxIdentificationTypes]);

  const country = useMemo(() => {
    return countries.find(
      (c) => c.id === client?.listAccountPhone[0]?.countryID
    );
  }, [client, countries]);

  return (
    <div className="flex flex-1 flex-col">
      <p className="font-light text-xl">
        {isShipper ? "Remitente" : "Destinatario"}:
      </p>
      <p className="font-medium text-gray-800 ">{client?.accountFullName}</p>
      <p className="text-gray-800 font-light leading-none">
        {identificationType?.abreviationName}
        {client?.identificationNumber}
      </p>
      <p className="text-gray-800 font-light leading-none">
        {country?.countryPhoneAccessCode}{" "}
        {client?.listAccountPhone[0]?.phoneNumber}
      </p>
      <p className="text-gray-800">
        {businessUnit?.code} - {businessUnit?.location.name}
      </p>

      <p
        className={classNames(
          "text-gray-800 text-sm",
          !showLocation && "hidden"
        )}
      >
        {`${location?.name} - ${location?.address} (${location?.postalCode})`}
      </p>
      <p
        className={classNames(
          "text-gray-800 text-sm",
          (!location?.reference || !showLocation) && "hidden"
        )}
      >
        Punto de ref: {location?.reference}
      </p>
    </div>
  );
};

export default ShipmentDetailsModal;
